import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import ShareIcon from 'public/images/share.svg'
import { useGetWishTranslation } from '@/services/wish/hooks'

import ComponentStyled from './styled'

type Props = {
  wishId?: number
  comment: string
  username: string
  location: string
  avatar?: string
  onShare?: () => void
}

const WishItem = ({ comment, username, location, avatar, wishId, onShare }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const [translateText, setTranslateText] = useState('')

  const { mutate: mutateWishTranslation } = useGetWishTranslation({
    onSuccess: data => {
      setTranslateText(data.text)
    },
    onError: () => {
      toast.error(t('An error has occurred'))
    },
  })

  const handleClickTranslate = () => {
    if (locale && wishId) {
      mutateWishTranslation({ wish: wishId, locale })
    }
  }

  return (
    <ComponentStyled avatar={avatar}>
      <p className='comment'>{translateText || comment}</p>
      <div className='username'>
        {avatar && <div className='avatar' />}
        <span>
          {username} — {location}
        </span>
        {onShare && <ShareIcon className='share-icon' onClick={onShare} />}
      </div>

      {wishId && locale && !translateText && (
        <p className='translate-wish' onClick={handleClickTranslate}>
          {t('Translate wish')}
        </p>
      )}
    </ComponentStyled>
  )
}

export default WishItem
