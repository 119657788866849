import { instance as axiosInstance } from '@/services/index'
import { GetTranslationType, PostWishType, WishPlanted } from '@/model/interfaces/wish'

export const postWish = async (data: PostWishType): Promise<WishPlanted> => {
  const url = `/wishes`
  const { data: resData } = await axiosInstance.post(url, { data })
  return resData
}

export const getWishes = async (params?: string) => {
  const formatParams = params || ''
  const url = `/wishes${formatParams}`
  const { data: resData } = await axiosInstance.get(url, { headers: { Authorization: false } })
  return resData
}

export const getWish = async (wishId: number | string, params?: string) => {
  const formatParams = params || ''
  const url = `/wishes/${wishId}${formatParams}`
  const { data: resData } = await axiosInstance.get(url)
  return resData
}

export const wishTranslation = async (data: GetTranslationType) => {
  const url = '/wish/getTranslation'
  const { data: resData } = await axiosInstance.post(url, { data })
  return resData
}
