import { QueryKey, useInfiniteQuery, useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'

import {
  GetTranslationType,
  GetWishesInterface,
  PostWishType,
  TextTranslated,
  WishDataInterface,
  WishPlanted,
} from '@/model/interfaces/wish'

import { getWish, getWishes, postWish, wishTranslation } from './api'

export const WISH_KEY = 'wish'
export const INFINITE_WISH_KEY = 'infiniteWishes'

export const usePostWish = (options?: UseMutationOptions<WishPlanted, AxiosError, PostWishType>) => {
  return useMutation(postWish, options)
}

export const useGetWishes = (
  params?: string,
  options?: UseQueryOptions<GetWishesInterface, Error, GetWishesInterface, QueryKey>
) => {
  const { data, ...rest } = useQuery<GetWishesInterface, Error>(
    [WISH_KEY, 'list', params],
    () => getWishes(params),
    options
  )
  return { data: data?.data || [], ...rest }
}

export const useGetInfiniteWishes = (params?: string) => {
  const { locale } = useRouter()
  let formatParams = params ? `${params}&locale=${locale}` : `?locale=${locale}`

  const data = useInfiniteQuery<GetWishesInterface, Error>(
    [INFINITE_WISH_KEY, 'list', formatParams],
    ({ pageParam = 1 }) => getWishes(`${formatParams}&pagination[page]=${pageParam}`),
    {
      getNextPageParam: lastPage => {
        const currentPage = lastPage?.meta?.pagination.page
        const pageCount = lastPage?.meta?.pagination.pageCount
        if (currentPage < pageCount) {
          return currentPage + 1
        }
        return false
      },
      getPreviousPageParam: lastPage => {
        const currentPage = lastPage?.meta?.pagination.page
        const pageCount = lastPage?.meta?.pagination.pageCount
        if (currentPage > pageCount) {
          return currentPage - 1
        }
        return false
      },
    }
  )

  return data
}

export const useGetWish = (
  wishId: number,
  params?: string,
  options?: UseQueryOptions<WishDataInterface, Error, WishDataInterface, QueryKey>
) => {
  const formatParams = params || ''

  const { data, ...rest } = useQuery<WishDataInterface, Error>(
    [WISH_KEY, 'detail', wishId, formatParams],
    () => getWish(wishId, formatParams),
    options
  )

  return { data: data?.data || null, ...rest }
}

export const useGetWishTranslation = (options?: UseMutationOptions<TextTranslated, AxiosError, GetTranslationType>) => {
  return useMutation(wishTranslation, options)
}
