import { GetCurrentMilestioneInterface } from '@/model/interfaces/milestone'
import PlantTypeInterface from '@/model/interfaces/plant-type'

export const removeWishStorage = () => {
  localStorage.removeItem('wish')
  localStorage.removeItem('selectedSeed')
}

export const setWishStorage = (text: string) => {
  return localStorage.setItem('wish', text)
}

export const getWishStorage = () => {
  return localStorage.getItem('wish') || ''
}

export const getSeedStorage = (): PlantTypeInterface => {
  const item = localStorage.getItem('selectedSeed') || ''
  return item ? JSON.parse(item) : ''
}

export const setSeedStorage = (plant: PlantTypeInterface) => {
  localStorage.setItem('selectedSeed', JSON.stringify(plant))
}

export const isObjectiveCompleted = (currentMilestone: GetCurrentMilestioneInterface) => {
  const currentWishes = currentMilestone?.current || 0
  const totalObjective = currentMilestone?.total || 0
  return currentWishes >= totalObjective && totalObjective > 0
}
