import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

import { GetPlantTypesInterface } from '@/model/interfaces/plant-type'

import { getPlantTypes } from './api'

export const PLANT_TYPE_KEY = 'plant-type'

export const useGetPlantTypes = (params?: string) => {
  const { locale } = useRouter()
  const formatParams = params ? `${params}&locale=${locale}` : `?locale=${locale}`

  const { data, ...rest } = useQuery<GetPlantTypesInterface, Error>([PLANT_TYPE_KEY, 'list', formatParams], () =>
    getPlantTypes(formatParams)
  )
  return { data: data?.data || [], ...rest }
}
