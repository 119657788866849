import { useQuery } from 'react-query'
import { useRouter } from 'next/router'

import { GetCurrentMilestioneInterface, GetMilestoneInterface } from '@/model/interfaces/milestone'

import { getCurrentMilestone, getMilestones } from './api'

export const MILESTONE_KEY = 'milestone'
export const CURRENT_MILESTONE_KEY = 'curret-milestones'

export const useGetCurrentMilestone = () => {
  const response = useQuery<GetCurrentMilestioneInterface, Error>(CURRENT_MILESTONE_KEY, getCurrentMilestone)
  return response
}

export const useGetMilestones = (params?: string) => {
  const { locale } = useRouter()
  const formatParams = params ? `${params}&locale=${locale}` : `?locale=${locale}`

  const { data, ...rest } = useQuery<GetMilestoneInterface, Error>([MILESTONE_KEY, 'list', formatParams], () =>
    getMilestones(formatParams)
  )
  return { data: data?.data || [], ...rest }
}

export const useGetReachedMilestones = (params?: string) => {
  const { data, ...rest } = useQuery<GetMilestoneInterface, Error>([MILESTONE_KEY, 'list', params], () =>
    getMilestones(params)
  )
  return { data: data?.data || [], ...rest }
}
