import styled from 'styled-components'

type Props = {
  avatar?: string
}

const ComponentStyled = styled.div<Props>`
  padding: 20px 40px;
  background: ${({ theme }) => theme.colors.secondaries.opacity10};
  border-radius: 20px;

  .comment {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.pureBlack};
    margin-bottom: 15px;
  }

  .translate-wish {
    text-align: center;
    margin: 15px 0 0 0;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: underline;
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar {
      position: relative;
      max-width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-right: 10px;
      background-image: ${({ avatar }) => `url("${avatar}")`};
      background-position: center;
      background-size: cover;
    }

    span {
      font-family: 'Cabin';
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: ${({ theme }) => theme.colors.black};
    }

    .share-icon {
      cursor: pointer;
      margin-left: 15px;
    }
  }
`

export default ComponentStyled
