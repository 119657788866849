import { instance as axiosInstance } from '@/services/index'
import { GetCurrentMilestioneInterface, GetMilestoneInterface } from '@/model/interfaces/milestone'

export const getCurrentMilestone = async (): Promise<GetCurrentMilestioneInterface> => {
  const url = '/milestone/getCurrent'
  const { data: resData } = await axiosInstance.get(url, { headers: { Authorization: false } })
  return resData
}

export const getMilestones = async (params?: string): Promise<GetMilestoneInterface> => {
  const url = `/milestones${params}`
  const { data: resData } = await axiosInstance.get(url, { headers: { Authorization: false } })
  return resData
}
