import React from 'react'

import Image from 'next/image'

import { strapiLoader } from '@/services/strapi'

import { ComponentStyled } from './styled'

type Props = {
  url: string
  title?: string
}

const Seed = ({ url, title }: Props): JSX.Element => (
  <ComponentStyled>
    <Image loader={strapiLoader} src={url} alt={title} layout='fill' objectFit='cover' />
  </ComponentStyled>
)

export default Seed
